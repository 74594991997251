@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
body{
  overflow-x: hidden;
  width: 100vw;
}
.font1 {
    font-family: "Montserrat, sans-serif";
  }
.font2 {
    font-family: "Poppins, serif";
  }